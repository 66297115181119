import React, {
  useState,
  useEffect
} from 'react'

import EventEmitter from '../../utils/EventEmitter'
import { useDispatch, useSelector } from 'react-redux'
import "../../assets/css/Callscreen.css"
import {
  fetchcallaction,
  autodial,
  autocalldial,
  AutoDialPlayParseBtn,
  autodialdatas, callmodule
} from '../../redux/Calls/CallAction'
import { wraptime } from '../../redux/SocketAction/SocketAction'
import { useParams, useLocation } from 'react-router-dom'
import { callLeadDetail } from '../../redux/background/BackgroundAction'
function AutoDialTemplate(props) {
  let tenant

  if (localStorage.getItem('config')) {
    tenant = JSON.parse(localStorage.getItem('config')).data.tenant_code
  }
  const agent = localStorage.getItem('user')
  const station = localStorage.getItem('extension')
  const location = useLocation()
  let [autodialtimer, setautodialtimer] = useState()
  let [list, setlist] = useState(true)
  let webrtccallstatus = localStorage.getItem('webrtcManual')
  let dialer_interval = JSON.parse(localStorage.getItem("campaigndetails")).wrap_time === 0 ? 2 : JSON.parse(localStorage.getItem("campaigndetails")).wrap_time
  let [autodialtimerstatus, setautodialtimerstatus] = useState(false)
  let [count1, setcount1] = useState(0)
  let dispatch = useDispatch()
  let stateValue = useSelector((state) => state)
  useEffect(() => {
    event()
  }, [])

  useEffect(() => {
    var timeout = null
    var interval = null
    if (autodialtimerstatus && webrtccallstatus == "false" && stateValue.call.dispoValidation == true) {
      EventEmitter.emit('autodial-timmer', true)
      interval = setInterval(() => {
        setautodialtimer((seconds) => {
          let sec
          if (seconds !== 0) {
            sec = seconds - 1
          } else if (seconds === 0) {
            if (stateValue.call.autodialdatas.length > 0) {
              sec = parseInt(localStorage.getItem('autodialtimer'))
            } else {
              sec = 0
            }

          }
          return sec
        })
      }, 1000)


      if (stateValue.call.autodialstatus) {
        if (count1 == 0) {

          if (stateValue.call.autodialdatas.length > 0) {
            setcount1(count1 + 1)
            wraptimer('start')
            timeout = setTimeout(() => {
              wraptimer('end')
              localStorage.setItem('webrtcManual', 'true')
              dispatch(autocalldial())
              dispatch(callmodule({
                id: stateValue.call.autodialdatas[0].lead_id,
                module: "lead",
                phone_no: stateValue.call.autodialdatas[0].phone_number,
                auto: true,
              }))
              let name = ""
              if (stateValue.call.autodialdatas[0].first_name === "N/A") {
                localStorage.setItem("dialpadname", stateValue.call.autodialdatas[0].phone_number);

              } else {
                localStorage.setItem("dialpadname", stateValue.call.autodialdatas[0].first_name);
                name = stateValue.call.autodialdatas[0].first_name
              }
              let leadData = {
                lead_id: stateValue.call.autodialdatas[0].lead_id,
                lead_name: name,
                phone_number: stateValue.call.autodialdatas[0].phone_number,
                module: "lead",
                render: 1
              }
              dispatch(callLeadDetail(leadData))

            }, dialer_interval * 1000)
          } else {
            localStorage.setItem("autodial", "false")
            dispatch(autodial(false))
            clearInterval(interval)
          }

        } else {
          wraptimer('start')
          timeout = setTimeout(() => {
            wraptimer('end')
            localStorage.setItem('webrtcManual', 'true')

            if (stateValue.call.autodialdatas.length > 0) {
              dispatch(autocalldial())
              dispatch(callmodule({
                id: stateValue.call.autodialdatas[0].lead_id,
                module: "lead",
                phone_no: stateValue.call.autodialdatas[0].phone_number,
                auto: true,
              }))
              let name = ""
              if (stateValue.call.autodialdatas[0].first_name === "N/A") {
                localStorage.setItem("dialpadname", stateValue.call.autodialdatas[0].phone_number);

              } else {
                localStorage.setItem("dialpadname", stateValue.call.autodialdatas[0].first_name);
                name = stateValue.call.autodialdatas[0].first_name
              }
              let leadData = {
                lead_id: stateValue.call.autodialdatas[0].lead_id,
                lead_name: name,
                phone_number: stateValue.call.autodialdatas[0].phone_number,
                module: "lead",
                render: 1
              }
              dispatch(callLeadDetail(leadData))
            } else {
              dispatch(autodial(false))
            }

          }, autodialtimer * 1000)
        }
      }
    } else {
      EventEmitter.emit('autodial-timmer', false)
      clearTimeout(timeout)
      clearInterval(interval)
    }
    return () => {
      clearTimeout(timeout)
      clearInterval(interval)
    }
  }, [autodialtimerstatus])

  useEffect(() => {
    setautodialtimer(parseInt(localStorage.getItem('autodialtimer')))
    if (stateValue.call.autodialstatus) {

      if (stateValue.call.autodialplayparsebtn == true) {
        setautodialtimerstatus(true)
      } else {
        setautodialtimerstatus(false)
      }

    } else {
      setautodialtimerstatus(false)
    }
  }, [stateValue.call.dispoValidation])
  function wraptimer(event) {
    if (JSON.parse(localStorage.getItem("campaigndetails")).wrap_time !== 0) {
      let data = { "event": "wrap", 'campaign': localStorage.getItem('selectedCampaign'), "tenant_id": tenant, "station": station, "agent": agent, "time": timestamp(), "action": event }
      dispatch(wraptime(stateValue.background.websocketconnection, data))
    }


  }
  function timestamp() {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(now.getDate()).padStart(2, '0');

    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime
  }
  function event() {
    EventEmitter.addListener('autodialpause', (data) => {
      setautodialtimerstatus(data)
      dispatch(AutoDialPlayParseBtn())
    })
    EventEmitter.addListener('pbx_error_dnd', (data) => {

      localStorage.setItem('webrtcManual', "false")

      setTimeout(() => {
        startStopbtnact(false)
        startStopbtnact(true)
      }, 2000);


    })

  }
  function startStopbtnact(data) {
    let readystate = localStorage.getItem("selectedPause")
    if (readystate == "available") {
      dispatch(AutoDialPlayParseBtn())
      setautodialtimerstatus(data)
    } else {
      EventEmitter.emit('error-message-getter', {
        action: 'ready',
        module: "Agent Not ready",
      })
    }
  }
  function resetautodial() {
    EventEmitter.emit('autodial-timmer', false)
    localStorage.setItem("autodial","false")
    dispatch(autodial(false))
    setautodialtimerstatus(false)
    dispatch(autodialdatas([]))
    props.showlist(true)
    EventEmitter.emit('auto-dial', {
      action: 'reset'
    })
  }
  function List(data) {
    setlist(data)
    props.showlist(data)
  }

  return (
    <>
      <div className='callscreen-autodial'>

        <p style={{ "margin": "2px 0px 0px 0px", "text-align": "center" }} className="callscreen-autodial-head autodial ">
          Preview Dial
        </p>
        {stateValue.call.autodialstatus && webrtccallstatus !== "true" && <p style={{ "margin": "2px 0px 0px 0px", "color": "#E43554" }} className="lead-count autodial">
          {autodialtimer}
        </p>}
        {stateValue.call.autodialstatus && webrtccallstatus !== "true" && localStorage.getItem("selectedPause") === "available" && <span
          onClick={() => { startStopbtnact(!stateValue.call.autodialplayparsebtn) }}
          className={
            stateValue.call.autodialplayparsebtn
              ? 'material-icons autodial lead-dial-button-green'
              : 'material-icons autodial  lead-dial-button-red'
          }
        >
          {stateValue.call.autodialplayparsebtn ? 'pause' : 'play_arrow'}
        </span>}
        {stateValue.call.autodialstatus && <span style={{ "color": "#2196f3" }}
          onClick={() => { resetautodial() }}
          className={'material-icons  autodial'}
        >
          restart_alt
        </span>}
        <span style={{ "color": "#2196f3" }}
          onClick={() => { List(!list) }}
          className={
            list === false
              ? 'material-icons autodial  autodial-list-bts'
              : 'material-icons  autodial'
          }
        >
          format_list_numbered
        </span>
      </div>
    </>
  )
}

export default AutoDialTemplate