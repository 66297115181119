import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { fetchcallaction } from '../redux/Calls/CallAction'
import { connect, useDispatch, useSelector } from 'react-redux'
import EventEmitter from '../utils/EventEmitter'

function CallDialPad(props) {
  let statevalue = useSelector((state) => state)
  let webrtc_session=statevalue.background.session
  const [dial, setDial] = useState('')

  const setDialFun = (number) => {
    if(props.type==="dtmf"){
      webrtc_session.sendDTMF(number)
    }
      const num = dial + '' + number
      setDial(num)
    
    
  }
  const clearText = () => {
    var text = dial.slice(0, -1)
    setDial(text)
  }

  const closeDialpad = (e) => {
    if (props.loaddialpad) {
      props.callAction('on_call')
    } else {
      props.dialpad(e)
    }
  }
  const sendnumber = (e) => {
    let readystate = localStorage.getItem("selectedPause")
    if(readystate==="available"){
      if(e != ""){
        if (props.othersdialpad) {
          props.dialpadnumber_transfer(e)
        } else {
          props.dialpadnumbers(e)
          props.callAction('on_log')
        }
      }else{
        EventEmitter.emit('error-message-getter', {
          action: 'ready',
          module: "Empty Number",
        })
      }
      
    }else{
      EventEmitter.emit('error-message-getter', {
        action: 'ready',
        module: "Agent Not ready",
      })
    }
   
  }

  return (
    <div className="dialpad">
      <div className="dialpad_sub_container">
        {props.calllogdialpad && (
          <div className="contact_head Dialpad_head">
            <h3>Dial Pad</h3>
            {/* <div>
              <span
                className="material-icons"
                onClick={() => closeDialpad(true)}
              >
                close
              </span>
            </div> */}
          </div>
        )}
        {<div className="numberpad">
          
          <Form.Control
            disabled={props?.transfer?.length > 0}
            type="text"
            readOnly={props.type==="dtmf"?true:false}
            value={dial}
            className="pad"
            onChange={(e) => {
              if (/^[\d*#+]*$/.test(e.target.value)){
               return setDial(e.target.value)
              }
              return
            }}
          />
          {props.type!=="dtmf"&&<span
            className={props?.transfer?.length > 0 ? "disabledbutton material-icons icon-cls" : 'enablebutton material-icons icon-cls'}
            style={{ marginLeft: 30 }}
            onClick={() => clearText()}
          >
            backspace
          </span>}
        </div>}
        <div  className={props?.transfer?.length > 0 ? "disabledbutton dialnumber" : 'dialnumber'}>
          <div>
            <span onClick={() => setDialFun(1)}>1</span>
            <span onClick={() => setDialFun(2)}>2</span>
            <span onClick={() => setDialFun(3)}>3</span>
          </div>
          <div>
            <span onClick={() => setDialFun(4)}>4</span>
            <span onClick={() => setDialFun(5)}>5</span>
            <span onClick={() => setDialFun(6)}>6</span>
          </div>
          <div>
            <span onClick={() => setDialFun(7)}>7</span>
            <span onClick={() => setDialFun(8)}>8</span>
            <span onClick={() => setDialFun(9)}>9</span>
          </div>
          <div>
            <span onClick={() => setDialFun('*')}>*</span>
            <span onClick={() => setDialFun(0)}>0</span>
            <span onClick={() => setDialFun('#')}>#</span>
          </div>
        </div>
        <div>
          {(statevalue.background.oncall === false || props.othersdialpad === true) && (
            <div>
              <Button
                className={ "callnow " }
                onClick={() => {
                  sendnumber(dial)
                }}
                disabled={props?.conf_isclicked||dial.length < 3 || props?.transfer?.length > 0}
              >
                Call Now
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
const mapDispatchToProps = (dispatch) => {
  return {
    callAction: (type) => dispatch(fetchcallaction(type)),
  }
}
export default connect(null, mapDispatchToProps)(CallDialPad)
