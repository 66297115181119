import React, { useState, useEffect } from 'react';
import Nav from '../components/navbar';
import { Outlet } from 'react-router-dom';
import CallScreen from '../components/CallScreen';
import { Drawer, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useLocation } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import gtawk from '../assets/images/gtawk.png';
import logo from '../assets/images/logo.png';
import Skyfall from '../assets/images/SKyfall logo.png';
import { useNavigate } from 'react-router'
function Layout() {
  const [callScreenFunction, setCallScreenFunction] = useState(false);
  const [outletData, setOutletData] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [metainformation, setmetainformation] = useState({});
  const [isMobileReady, setIsMobileReady] = useState(false); 
  const location = useLocation();
  let navigate = useNavigate()
  const [announcementpresent, setannouncementpresent] = useState(true);
  // Check if screen size is mobile
  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    setIsMobileReady(true);
    domain_name()
    if (isMobile) {
      setIsOpen(true);
    }
  }, [location.pathname, isMobile]);

  const triggerCallScreenFunction = (data) => {
    if (data === false) {
      setOutletData(data);
    } else {
      setCallScreenFunction(data);
    }
  };

  const returnonCallFunction = () => {
    setCallScreenFunction(false);
  };

  const domain_name = () => {
   let metadata= JSON.parse(localStorage.getItem("metadata"))
   setmetainformation(metadata)
  };

  const passDataToOutlet = () => {
    setIsOpen(true);
    setOutletData(true);
  };

  const toggleDrawer = (open) => () => {
    setIsOpen(open);
  };

  // Corrected refreshProp function
  const refreshProp = () => {
    if (isMobile) {
      setIsOpen(true)
    } else {
      navigate('/dashboard')
    }

    console.log('refreshProp function triggered');
  };

  const popupOpen = () =>{
    if (isMobile) {
      setIsOpen(true)
    } 
  }
const redirectscreen=()=>{
  if(isOpen===false && isMobile){
    setIsOpen(true)
  }
}
  const list = () => (
    <div
   
      role="presentation"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      {/* Header with logo and close button */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '10px 55px',
          height: "60px",
          borderBottom: '1px solid #ddd',
          backgroundColor: '#fff',
          flexShrink: 0,  // Prevent header from shrinking
        }}
      >
        { (
          <img src={metainformation?.logo} alt="Text" style={{ maxHeight: 50, maxWidth: 90 }} />
        )}
    
        <IconButton onClick={toggleDrawer(false)}>
          <CloseIcon />
        </IconButton>
      </div>

      {/* Scrollable body content */}
      <div
      
        style={{
          flex: 1,
          backgroundColor: '#f5f5f5',
          overflowY: 'auto',
          overflowX: 'hidden',  // Prevent horizontal scrolling
        }}
      >
        <Outlet context={{ triggerCallScreenFunction, outletData, isMobile ,popupOpen}} />
      </div>
    </div>
  );
  const announcementresize = () => {
    setannouncementpresent(false)
  };

  if (!isMobileReady) return null;
  return (
    <>
      {isMobile ?  (
        <>
          <Drawer anchor="left" open={isOpen} onClose={toggleDrawer(false)} style={{ height: '100%' }}>
            {list()}
          </Drawer>
          <div className="main-container">
            <Nav refreshProp={refreshProp} announcementresize={announcementresize} popupOpen={popupOpen}/>
            <div className={announcementpresent?"content":"contentnone"}>
              <CallScreen
                passDataToOutlet={passDataToOutlet}
                onCallFunction={callScreenFunction}
                returnonCallFunction={returnonCallFunction}
                refreshProp={redirectscreen}
                popupOpen={popupOpen}
              />
            </div>
          </div>
        </>
      ) :  
      <div className={`app-wrapper ${isMobile ? 'mobile_hide' : ''}`}>
        <div className="main-container">
        <Nav refreshProp={refreshProp} announcementresize={announcementresize} popupOpen={popupOpen} />
          <div className={announcementpresent?"content":"contentnone"}>
            <Outlet context={{ triggerCallScreenFunction, outletData, isMobile, popupOpen }} />
            <div className="scroll-rgt" md={4}>
              <CallScreen
                passDataToOutlet={passDataToOutlet}
                onCallFunction={callScreenFunction}
                returnonCallFunction={returnonCallFunction}
                refreshProp={redirectscreen}
                popupOpen={popupOpen}
              />
            </div>
          </div>
        </div>
      </div>}
      {/* <div className={`app-wrapper ${isMobile ? 'mobile_hide' : ''}`}>
        <div className="main-container">
          <Nav refreshProp={refreshProp} />
          <div className="content">
            <Outlet context={{ triggerCallScreenFunction, outletData }} />
            <div className="scroll-rgt" md={4}>
              <CallScreen
                passDataToOutlet={passDataToOutlet}
                onCallFunction={callScreenFunction}
                returnonCallFunction={returnonCallFunction}
                refreshProp={redirectscreen} 
              />
            </div>
          </div>
        </div>
      </div> */}
      {/* {isMobile && (
        <>
          <Drawer anchor="left" open={isOpen} onClose={toggleDrawer(false)} style={{ height: '100%' }}>
            {list()}
          </Drawer>
          <div className="main-container">
            <Nav refreshProp={refreshProp} />
            <div className="content">
              <CallScreen
                passDataToOutlet={passDataToOutlet}
                onCallFunction={callScreenFunction}
                returnonCallFunction={returnonCallFunction}
                refreshProp={redirectscreen} 
              />
            </div>
          </div>
        </>
      )} */}
    </>
  );
}

export default Layout;
