import React, { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Unauth from './pages/Error/Unauth'
import AppPermission from './AppPermission'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/App.css'
import Login from './pages/Login'
import SelectCRM from './pages/SelectCRM'
import Dashboard from './pages/Dashboard'
import Script from './pages/Script'
import Layout from './layout/Layout'
import Leads from './pages/Leads'
import LeadView from './pages/LeadView'
import VoicemailView from './pages/VoicemailView'
import MeetingView from './pages/MeetingView'
import ContactView from './pages/ContactView'
import Contacts from './pages/Contacts'
import TicketView from './pages/TicketView'
import Meeting from './pages/Meeting'
import History from './pages/History'
import VoiceMail from './pages/VoiceMail'
import ProtectedRoutes from './ProtectedRoutes'
import ErrorPage from './pages/Error/ErrorPage'
import ForgotPassword from './pages/ForgotPassword'
import Tickets from './pages/Tickets'
import Tost from '../src/components/Toster'
import EventEmitter from '../src/utils/EventEmitter'
import TicketCreate from './components/Tickets/TicketCreate'
import ContactCreate from './components/Contacts/ContactCreate'
import LeadCreate from './components/Lead/LeadCreate'
import CustomModule from './components/customModule'
import gtwakIcon from './assets/images/gtawk.png'
import skyFall from './assets/images/SKyfall logo.png'
import dooIcon from './assets/images/mini_logo.png'
import { getVersionConfig } from '../src/utils/constant'
import NetworkStatus from './components/NetworkStatus'
import { connect, useDispatch } from 'react-redux';
import { getdomain } from './redux/actions/login'
import { checkmemory } from './redux/memoryCheck/CheckmemoryAction'

function App(props) {
  let dispatch = useDispatch()
  const [errormessage, seterrormessage] = useState('')
  const [color, setcolor] = useState("red")
  const [metadatainfo,setmetadatainfo]=useState({})
  const [forgotMessage, setForgotMessage] = useState('we just sent you an email to reset your password.')
  const [createstate, setcreate] = useState('Unable to create')
  const [updatestate, setupdate] = useState('Unable to update')
  const [uploadstate, setuploadstate] = useState('File size is too large (max 5mb)')
  const [deletestate, setdelete] = useState('Unable to delete')
  const [fetchstate, setfetch] = useState('Unable to fetch')
  const [searchstate, setsearch] = useState('Unable to search')
  const [logout, setlogout] = useState('Unable to ')
  const [login, setlogin] = useState('Unable to ')
  const [user, setlgin] = useState('User already exists ')
  const [exceed, setexced] = useState('User Limit Exceed')
  const [invalid, setinvalid] = useState('Invalid email or password')
  const [email, setemail] = useState('Please enter email')
  const [password, setpassword] = useState('Please enter  password')
  const [phone, setphone] = useState('Please enter phone number')
  const [transfer, setTransfer] = useState('Transfer')
  const [voiceConn, setVoiceConn] = useState('Voice Connection')

  function useDynamicVersion() {
    const [versionSwitched, setVersionSwitched] = useState(false);

    const apInfo = JSON.parse(localStorage.getItem("apinfo")) || {};
    const subVersion = apInfo.sub_version;
    if (subVersion) {
      const newPath = window.location.pathname.replace(/\/v\d+\.\d+\.\d+\//, `/${subVersion}/`);
      if (newPath !== window.location.pathname) {
        window.history.replaceState({}, '', newPath);
        setVersionSwitched(true);
      }
    }

    return { versionSwitched };
  }

  const { versionSwitched } = useDynamicVersion();

  useEffect(() => {
    if (versionSwitched) {
      console.log("darsh versionSwitched", versionSwitched);
      window.location.reload();
    }
  }, [versionSwitched]);



  useEffect(() => {
    event()
    dispatch(checkmemory())
  }, [])
  useEffect(async() => {
    console.log('=====>', window.location.hostname);
    const baseURL = window.location.hostname;
    const faviconLink = document.createElement('link');
    let metainfo= await domain_name()
    faviconLink.rel = 'icon';
      faviconLink.href = metainfo.logo;
    document.title = metainfo.name + ' Agent Portal';


    document.head.appendChild(faviconLink);

  }, []);
  const domain_name =async () => {
    var parts = window.location.hostname.split(".");
      let domain
      if (window.location.hostname === "localhost") {
        domain = "localhost"
      } else {
        domain = parts[parts.length - 2];
      }
      
      const domainurl = await dispatch(getdomain(domain));
      let data=domainurl.data.data[0]
      localStorage.setItem("metadata",JSON.stringify(data))
      return data

      
  }
  function event() {
    EventEmitter.addListener('error-message-getter', (data) => {
      if (data.action === 'upload') {
        seterrormessage(`${uploadstate} ${data.module}`)
        setcolor("red")
      }
      if (data.action === 'filter_Duplicate') {
        seterrormessage(`${data.module}`)
        setcolor("red")
      }
      if (data.action === 'create') {
        seterrormessage(`${createstate} ${data.module}`)
        setcolor("red")
      }
      if (data.action === 'fav') {
        seterrormessage(`${data.module}`)
        setcolor("red")
      }
      if (data.action === 'updated') {
        seterrormessage(forgotMessage)
        setcolor('green')
      }
      if (data.action === 'fetch') {
        seterrormessage(`${fetchstate} ${data.module}`)
        setcolor("red")
      }
      if (data.action === 'update') {
        seterrormessage(`${updatestate} ${data.module}`)
        setcolor("red")
      }
      if (data.action === 'delete') {
        seterrormessage(`${deletestate} ${data.module}`)
        setcolor("red")
      }

      if (data.action === 'search') {
        seterrormessage(`${searchstate} ${data.module}`)
        setcolor("red")
      }
      if (data.action === 'logout') {
        seterrormessage(`${logout} ${data.module}`)
        setcolor("red")
      }
      if (data.action === 'login') {
        seterrormessage(`${login} ${data.module}`)
        setcolor("red")
      }
      if (data.action === 'user') {
        seterrormessage(`${user}`)
        setcolor("red")
      }
      if (data.action === 'field') {
        seterrormessage(`${data.module}`)
        setcolor("orange")
      }
      if (data.action === 'exceed') {
        seterrormessage(`${exceed}`)
        setcolor("red")
      }
      if (data.action === 'invalid') {
        seterrormessage(`${invalid}`)
        setcolor("red")
      }
      if (data.action === 'email') {
        seterrormessage(`${email}`)
        setcolor("red")
      }
      if (data.action === 'password') {
        seterrormessage(`${password}`)
        setcolor("red")
      }
      if (data.action === 'phone') {
        seterrormessage(`${phone}`)
        setcolor("red")
      }
      if (data.action === 'socket') {
        seterrormessage(`${data.module}`)
        setcolor("red")
      }
      if (data.action === "template_success") {
        seterrormessage(`${"message successfully sended"}`)
        setcolor("green")
      }
      if (data.action === "createform") {
        seterrormessage(`${data.module}  successfully created`)
        setcolor("green")
      }
      if(data.action === "template_failed"){
        seterrormessage('message sending fail')
        setcolor("red")
      }
      if (data.action === "message") {
        seterrormessage(`${data.module}`)
        setcolor("red")
      }
      if (data.action === "updatesuccess") {
        seterrormessage(`${data.module}`)
        setcolor("green")
      }
      if (data.action === 'noNumber') {
        seterrormessage(`${phone} or extension`)
        setcolor('red')
      }
      if (data.action === 'Xfer_Failed') {
        seterrormessage(`${transfer} Fail`)
        setcolor('red')
      }
      if (data.action === 'xfer_success') {
        seterrormessage(`${transfer} Success`)
        setcolor('green')
      }
      if (data.action === "ready") {
        seterrormessage(`${data.module}`)

        if (data.module === "Agent ready successfully") {
          setcolor("green")
        } else {
          setcolor("red")
        }

      }
      if (data.action === 'systemconfig') {
        seterrormessage(`SystemConfig Failed to fetch`)
        setcolor("red")
      }
      if (data.action === 'voiceconnfail') {
        seterrormessage(`${voiceConn} Fail`)
        setcolor('red')
      }
    })
    EventEmitter.addListener('error-message-getter-response', () => {
      seterrormessage('')
    })
  }

  return (
    <div className="App">
      <NetworkStatus />
      <Tost errormessage={errormessage} color={color} type={'snackbar'}></Tost>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        {/* <BrowserRouter> */}
        <Routes>
          <Route path="/" exact element={<Login />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/select" element={<SelectCRM />} />
            <Route path="/dashboard" element={<AppPermission type="/dashboard" />}>
              <Route index element={<Dashboard />} />
            </Route>
            <Route path="/leads" element={<AppPermission type="/leads" />}>
              <Route index element={<Leads />} />
              <Route path=":phone_number/:lead_id" element={<LeadView />} />
            </Route>
            <Route path="/leads/create" element={<AppPermission type="/leads/create" />}>

              <Route index element={<LeadCreate />} />
            </Route>
            <Route path="/tickets" element={<AppPermission type="/tickets" />}>
              <Route index element={<Tickets />} />
              {/* <Route path="/create" element={<TicketCreate />} /> */}
              <Route path=":phone_number/:ticket_id" element={<TicketView />} />
            </Route>

            <Route path="/tickets/create" element={<AppPermission type="/tickets/create" />}>
              <Route index element={<TicketCreate />} />
            </Route>

            <Route path="/meeting" element={<AppPermission type="/meeting" />}>
              <Route index element={<Meeting />} />
              <Route path=":phone_number/:meeting_id" element={<MeetingView />} />
            </Route>
            <Route path="/voicemail" element={<AppPermission type="/voicemail" />}>
              <Route index element={<VoiceMail />} />
              <Route path=":phone_number" element={<VoicemailView />} />
            </Route>
            <Route path="/script" element={<AppPermission type="/script" />}>
              <Route index element={<Script />} />

            </Route>
            <Route path="/contact" element={<AppPermission type="/contact" />}>
              <Route index element={<Contacts />} />
              <Route path=":phone_number/:contact_id" element={<ContactView />} />

            </Route>
            <Route path="/contact/create" element={<AppPermission type="/contact/create" />}>

              <Route index element={<ContactCreate />} />
            </Route>
            <Route path="/contact/create/:lead_id/:phone_number" element={<AppPermission type="/contact/create/lead:phone_number" />}>

              <Route index element={<ContactCreate />} />
            </Route>

            <Route path="/custom-module" element={<AppPermission type="/custom-module" />}>

              <Route path=":module" element={<CustomModule />} />

            </Route>
          </Route>
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
