import api from "../../utils/axios-util";

export const GetLeadTable = (selectedCampaign) => (dispatch) =>
  new Promise(function (resolve, reject) {
    dispatch({
      type: 'SET_SAVING',
      saving: true
    });
    api.get(`/asterdialer/autodial/sub-filters?id=${selectedCampaign}`).then(response => {
      dispatch({
        type: 'SET_SAVING',
        saving: false
      });

      resolve(response);
    }).catch(error => {
      reject(error);
    })
  });

export const GetColorCode = (module) => (dispatch) =>
new Promise(function (resolve, reject) {
  
  api.get(`/colorcondition?module=${module}`).then(response => {
    
    resolve(response);
  }).catch(error => {
    reject(error);
  })
});
  
export const GetLeadSearch = (payload) => (dispatch) =>
  new Promise(function (resolve, reject) {
    dispatch({
      type: 'SET_SAVING',
      saving: true
    });
    api.post(`/fetch/record`, payload).then(response => {
      dispatch({
        type: 'SET_SAVING',
        saving: false
      });

      resolve(response);
    }).catch(error => {
      reject(error);
    })
  });
export const filterCustommodule = (payload) => (dispatch) =>
  new Promise(function (resolve, reject) {
    dispatch({
      type: 'SET_SAVING',
      saving: true
    });
    api.post(`/asterdialer/Custommodule/filter `, payload).then(response => {
      dispatch({
        type: 'SET_SAVING',
        saving: false
      });

      resolve(response);
    }).catch(error => {
      reject(error);
    })
  });
export const GetLeadStataus = (payload) => (dispatch) =>
  new Promise(function (resolve, reject) {
    dispatch({
      type: 'SET_SAVING',
      saving: true
    });
    api.get(`/fetch/lead?status=Active`).then(response => {
      dispatch({
        type: 'SET_SAVING',
        saving: false
      });

      resolve(response);
    }).catch(error => {
      reject(error);
    })
  });

export const AsterLeadFetch = (leadNumber) => (dispatch) =>
  new Promise(function (resolve, reject) {
    dispatch({
      type: 'SET_SAVING',
      saving: true
    });
    api.get(`/asterdialer/customer?number=${leadNumber}`).then(response => {
      dispatch({
        type: 'SET_SAVING',
        saving: false
      });

      resolve(response);
    }).catch(error => {
      reject(error);
    })
  });

export const GetCustomerTimeline = (payload) => (dispatch) =>
  new Promise(function (resolve, reject) {
    dispatch({
      type: 'SET_SAVING',
      saving: true
    });
    api.post(`/asterdialer/customer/timeline`, payload).then(response => {
      dispatch({
        type: 'SET_SAVING',
        saving: false
      });

      resolve(response);
    }).catch(error => {
      reject(error);
    })
  });

export const CallActivity = (data) => {

  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      // http://localhost:4001
      await api
        .post(`/doocity/timeline/call_activity?phone_number=${data.phone_number}&id=${data.id}&skip=${data.skip}&take=${data.take}&module=${data.module}`,{lead_id:data.lead_id})
        .then((response) => {

          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const HistoryTimeline = (data) => {

  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      let url = `/doocity/history?id=${data.id}&skip=${data.skip}&take=${data.take}&module=${data.module}`
      if (data.module === "lead") {
        url += `&templateName=${data.template_name}`
      }
      await api
        .get(url)
        .then((response) => {

          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};


export const MeetingFetch = (value) => {
  return (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      await api
        .get(`/asterdialer/meetings?userID=${value.userID}&skip=${value.skip}&take=${value.take}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
};

export const CreateNote = (payload) => (dispatch) =>
  new Promise(function (resolve, reject) {
    api.post(`/notelog/create`, payload).then(response => {
      dispatch({
        type: 'SET_SAVING',
        saving: false
      });

      resolve(response);
    }).catch(error => {
      reject(error);
    })
  });

export const LeadScript = (payload) => (dispatch) =>
  new Promise(function (resolve, reject) {
    api.get(`/aster/script?script_name=${payload}&status=ACTIVE`).then(response => {

      resolve(response);
    }).catch(error => {
      reject(error);
    })
  });


  export const GetSource = () => () =>
  new Promise(function (resolve, reject) {
    api.get(`/source/fetch?active=0`).then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    })
  });

export const getfilter = (module) => () =>
  new Promise(function (resolve, reject) {
    let team = ""
    let teamdata = JSON.parse(localStorage.getItem("Teaminfo"))
    if (Object.keys(teamdata).length > 0) {
      team = teamdata.name
    }
    api.get(`/asterdialer/autodial/get-filters?user_name=${localStorage.getItem('user')}&module=${module}&template_name=${JSON.parse(localStorage.getItem('selectedCampaign_data')).template_name}&team=${team}`).then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    })
  });

  export const addfilter = (payload) => () =>
  new Promise(function (resolve, reject) {
    api.post("/asterdialer/autodial/add-filters", payload).then(response => {
      resolve(response);
    }).catch(error => {
      reject(error);
    })
  });

  export const getfilterdata = (data) => () =>
  new Promise(function (resolve, reject) {
    data['template_name']= JSON.parse(localStorage.getItem('selectedCampaign_data')).template_name
    api.post(`/asterdialer/autodial/sub-filters`,data).then(response => {

      resolve(response);
    }).catch(error => {
      reject(error);
    })
  });
  export const GetTimezone = () => () =>
  new Promise(function (resolve, reject) {
    api.get(`/timezone/fetch?skip=&take=&status=Y`).then(response => {

      if(response.status == 204){
        response.data.data =[]
        response.data.count=0
    }
    resolve(response.data);
    }).catch(error => {
      reject(error);
    })
  });
  export const Getsearch = (url,payload) => (dispatch) =>
    new Promise(function (resolve, reject) {
      dispatch({
        type: 'SET_SAVING',
        saving: true
      });
      api.post(url,payload).then(response => {
        dispatch({
          type: 'SET_SAVING',
          saving: false
        });
  
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

    export const GetCommonSearch = (module,payload) => (dispatch) =>
      new Promise(function (resolve, reject) {
        api.post(`/commonsearch?module=${module}`,payload).then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        })
      });
