import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Form } from 'react-bootstrap'
import EventEmitter from '../../utils/EventEmitter'
import fromat from '../../utils/format-text'
import {
  AsterAbandoned,
  AsterAbandonedSearch,
} from '../../redux/actions/SelectCRM'
import { useNavigate } from 'react-router'
import { PopupRouter } from '../../redux/actions/Customform'
import { Encode } from '../../utils/EncodeDecode'
import LoadingSpinner from '../LoadingSpinner'
function CalllogAll(props) {
  let navigate = useNavigate()
  var agent = localStorage.getItem('user')
  let campaigninfo = JSON.parse(localStorage.getItem("selectedCampaign_data"))
  let wasession = localStorage.getItem("wasession")
  let AppPermission
  if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
  }
  let dispatch = useDispatch()
  const [call_log, setcall_log] = useState([])
   let [loader,setloader]= useState(false)
  const [infinitScroll, setinfinitScroll] = useState({
    limit: 7,
    more: true,
  })
  const [skipdata, setskipdata] = useState({
    skip: 0,
    take: 10,
  })

  let [count, setcount] = useState(0)

  const [values, Setvalue] = useState('')

  useEffect(() => {
    fetchCallLog('All', skipdata)
  }, [])

  let fetchMoreData = () => {
    if (call_log.length >= count) {
      setinfinitScroll((set) => {
        set.more = false
        return {
          ...set,
        }
      })
    } else {
      setTimeout(() => {
        if (values === '') {
          fetchCallLog('All', skipdata,"Moredata")
        } else {
          searchTerm(values, 'lazyload',"Moredata")
        }
      }, 1000)
    }
  }

  const fetchCallLog = async (data, payloadValue,Moredata) => {
    let payload = {
      agent: agent,
      call_status: data,
      skip: payloadValue.skip,
      take: payloadValue.take,
    }
    if(Moredata !== "Moredata"){
      setloader(true)
    }

    dispatch(AsterAbandoned(payload))
      .then(async function (response) {
    setloader(false)

        setcount(response.data.count)

        if (payload.skip === 0) {
          setskipdata((set) => {
            set.take = 10
            set.skip = 10
            return {
              ...set,
            }
          })

          setcall_log([].concat(response.data.data))
        } else {
          setcall_log(call_log.concat(response.data.data))
          setskipdata((set) => {
            set.take = set.take
            set.skip = set.skip + 10
            return {
              ...set,
            }
          })
        }
        setinfinitScroll((set) => {
          set.limit = set.limit + set.limit

          return {
            ...set,
          }
        })
      })

      .catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'abandon',
        })
      })
  }

  function searchTerm(value, lazyload,Moredata) {
    if (value != '') {
      let payload
      if (lazyload === 'lazyload') {
        Setvalue(value)

        payload = {
          agent: agent,
          call_status: 'All',
          skip: skipdata.skip,
          take: 10,
          value: value,
        }
      } else {
        Setvalue(value)
        setinfinitScroll((set) => {
          set.more = true
          return {
            ...set,
          }
        })
        payload = {
          agent: agent,
          call_status: 'All',
          skip: 0,
          take: 10,
          value: value,
        }
      }
      if(Moredata != "Moredata"){
        setloader(true)
      }
      dispatch(AsterAbandonedSearch(payload))
        .then(async function (response) {
          setloader(false)
          if (response.data.status === 200) {
            if (lazyload === 'lazyload') {
              setcall_log(call_log.concat(response.data.data))

              setskipdata((set) => {
                set.take = 10
                set.skip = payload.skip + 10
                return {
                  ...set,
                }
              })
              setcount(response.data.count)
            } else {
              setcall_log([].concat(response.data.data))

              setskipdata((set) => {
                set.take = 10
                set.skip = 10
                return {
                  ...set,
                }
              })
              setcount(response.data.count)
            }
          } else {
            setcall_log([].concat(response.data.data))
          }
        })

        .catch((err) => {
          EventEmitter.emit('error-message-getter', {
            action: 'search',
            module: 'abandon',
          })
        })
    } else {
      Setvalue(value)
      setcall_log([])
      setskipdata((set) => {
        set.take = 10
        set.skip = 0
        return {
          ...set,
        }
      })
      let payload = {
        skip: 0,
        take: 10,
      }
      setinfinitScroll((set) => {
        set.more = true
        return {
          ...set,
        }
      })
      fetchCallLog('All', payload)
    }
  }

  function getnumber(data, name, id, module) {
    props.number([data, name, id, module])

  }
  function timeSince(value) {

    const today = new Date(value);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();
    let hh = today.getHours();
    let MM = today.getMinutes();
    let ss = today.getSeconds();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    if (hh < 10) hh = "0" + hh;
    if (MM < 10) MM = "0" + MM;
    if (ss < 10) ss = "0" + ss;

    const formattedToday =
      yyyy + "-" + mm + "-" + dd + " " + hh + ":" + MM + ":" + ss;
    return formattedToday;

    // let date = new Date(value)
    // var seconds = Math.floor((new Date() - date) / 1000)

    // var interval = seconds / 31536000

    // if (interval > 1) {
    //   return Math.floor(interval) + ' year ago'
    // }
    // interval = seconds / 2592000
    // if (interval > 1) {
    //   return Math.floor(interval) + ' month ago '
    // }
    // interval = seconds / 86400
    // if (interval > 1) {
    //   return Math.floor(interval) + ' day ago'
    // }
    // interval = seconds / 3600
    // if (interval > 1) {
    //   return Math.floor(interval) + ' hour ago'
    // }
    // interval = seconds / 60
    // if (interval > 1) {
    //   return Math.floor(interval) + ' minute ago'
    // }
    // return Math.floor(seconds) + ' second ago'
  }
  function navigater(phone) {

    let payload = {
      template_name: campaigninfo.template_name,
      phone_number: phone
    }
    let process = localStorage.getItem('process')
    if (process === "leads" || process === "leads,meetings" || process === "leads,tickets,meetings" || process === "leads,tickets") {
      payload.module = 'leads'
      dispatch(PopupRouter(payload)).then((res) => {
        if (res.data.ids.length > 0) {
          let id = res.data.ids[0].lead_id
          navigate(`/leads/${Encode(phone)}/${id}`)
        } else {
          navigate('/leads/create')

        }
      }).catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'popup router',
        })
      })
    } else if (process === "tickets" || process === "tickets,meetings") {
      payload.module = 'tickets'
      dispatch(PopupRouter(payload)).then((res) => {
        if (res.data.ids.length > 0) {
          let id = res.data.ids[0].ticket_id
          navigate(`/tickets/${Encode(phone)}/${id}`)
        } else {

          navigate('/tickets/create')

        }
      }).catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'popup router',
        })
      })
    }
    else if (process === "meetings") {
      payload.module = 'meetings'
      dispatch(PopupRouter(payload)).then((res) => {
        if (res.data.ids.length > 0) {
          let id = res.data.ids[0].fwp_id
          navigate(`/meeting/${Encode(phone)}/${id}`)
        } else {

          if (res.data.contact_id.length > 0) {
            navigate(`/contact/${Encode(phone)}/${res.data.contact_id[0].id}`)
          } else {
            navigate('/contact/create')
          }


        }
      }).catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'popup router',
        })
      })
    }


    props.popupOpen()

  }
  return (
    <div className="callscreen-log-div">
      {AppPermission.calllog_search && <div className="callscreen-log-div-top">
        <span className="material-icons callscreen-search-icon">search</span>
        <Form.Control
          onChange={(event) => {
            searchTerm(event.target.value)
          }}
          type="text"
          placeholder="Search"
          style={{ paddingLeft: 40, height: '30px', marginTop: '10px' }}
        />
      </div>}
      <div className="callscreen-log-div-bottom">
        <div
          id="abandonedDiv"
          style={{
            height: '100%',
            overflow: 'auto',
            color: 'grey',
            textAlign: 'center',
          }}
        >
          <InfiniteScroll
            dataLength={call_log.length}
            next={fetchMoreData}
            hasMore={infinitScroll.more}
            scrollableTarget="abandonedDiv"
          >
            {call_log.length > 0 && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {call_log.map((ele, k) => {
                  return (
                    <div
                      key={k.toString()}
                      className="d-flex align-items-center callloghover"
                      style={{
                        // marginTop: 10,
                        borderBottom: '1px solid rgb(40 48 54 / 10%)',
                        padding: '10px',
                      }}
                    >
                      {AppPermission.click_to_call && wasession === "false" && <div
                        style={{ cursor: 'pointer' }}
                        className="material-icons complete_call"
                        onClick={() => getnumber(ele.phone_number, ele.lead_name, ele.lead_id, ele.module)}
                      >
                        phone
                      </div>}
                      <div onClick={() => navigater(ele.phone_number, ele.lead_id, ele.module)}
                        className="d-flex justify-content-between"
                        style={{ width: '100%', paddingLeft: 10, cursor: 'pointer' }}
                      >
                        <>
                          <div className="calls">
                            <h3>
                              {ele.lead_name === null || ele.lead_name === "null" ||
                                ele.lead_name === '' ||
                                ele.lead_name === undefined
                                ? fromat(ele.phone_number, { maskbool: true })
                                :props.reduceLength(fromat(ele.lead_name, { maskbool: true }))}
                            </h3>
                            <p>{fromat(ele.call_status)}</p>
                          </div>
                          <div className="date">
                            <h3>
                              {timeSince(ele.calldate)}
                            </h3>

                            <p>{ele.duration}</p>
                          </div>
                        </>

                      </div>
                    </div>
                  )
                })}
              </div>
            )}
            {call_log.length === 0 && loader == false && (
              <div className="nodata log-noanswer">No Data</div>
            )}
            { loader && <LoadingSpinner />}

          </InfiniteScroll>
        </div>
      </div>
    </div>
  )
}

export default CalllogAll
