import React, { useState, useRef } from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Col, Form, Row, Button, Card, Dropdown } from 'react-bootstrap'
import CallScreen from '../components/CallScreen'
import ContactTable from '../components/Table'
import EventEmitter from '../utils/EventEmitter'
import { useDispatch, useSelector } from 'react-redux'
import { ContactFetch } from '../redux/actions/ContactActions'
import '../assets/css/Leads.css'
import Filters from '../components/filter'
import InfiniteScroll from 'react-infinite-scroll-component'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { Outbound } from '../redux/SocketAction/SocketAction'
import { callmodule } from '../redux/Calls/CallAction'
import Format from '../utils/format-text'
import { Encode } from '../utils/EncodeDecode'
import Dialogbox from '../components/Dialogbox'
import { getTemplate } from '../redux/actions/ChatActions'
import { waTemplate } from '../redux/SocketAction/SocketAction'
import waicon from '../assets/images/whatsapp.svg'
import { AsterAgentTableField, AsterAgentTableUpdate } from '../redux/actions/AsterHeader'
import DynamicField from '../components/DynamicField'
import { adminPermission } from '../utils/localstorage-provider'
function Contacts() {
  let campaigninfo = JSON.parse(localStorage.getItem('selectedCampaign_data'))
  let agent = localStorage.getItem('user')
  let selected_campaign = localStorage.getItem('selectedCampaign')
  let [wadata, setwadata] = useState([])
  let [dynamicHead,setDynamicHead] = useState(false)
  const [popUpData, setpopUpData] = useState({
    open: false,
    Type: 'form_bar',
    field: [
      {
        type: 'SelectList',
        label: 'Whatsapp Template',
        placeholder: ' select message',
        required: true,
        model: 'whatsapp_template',
        option: [],
        multiple: false,
        value: '',
      },
    ],
    title: 'Whatsapp Template',
    Message: 'Selected Successfully',
    Butonstatus: 'Send',
  })
  const [searchselect, setsearchselect] = useState([])
  let wasession = localStorage.getItem("wasession")
  let stateValue = useSelector((state) => state)
  let dispatch = useDispatch()
  let AppPermission
  if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
  }
  let navigate = useNavigate()
  const [infinitScroll, setinfinitScroll] = useState({ limit: 7, more: true })
  const [searchContactValue, setsearchContactValue] = useState("")
  const [contactDetails, setcontactDetails] = useState([])
  const [skipdata, setskipdata] = useState({
    skip: 0,
    take: 20,

  })
  let [maskphoneindex,setmaskphoneindex] = useState({index:0,value:""})
  let [count, setcount] = useState(0)
      let [loader,setloader]= useState(false)
  const [header, setheader] = useState([])

  function checkheaderAction() {
    let autodialstatus = true
    if(stateValue.call.autodialstatus){
      autodialstatus = false
    }

    return {
      Header: () => (
        <div >
          <span>Action</span>
        </div>
      ),
      accessor: 'action',
      isrender: false,
      Cell: ({ row }) => {
        return (
          <div className="lead_action">
            {<span
              className="material-icons"
              style={{ color: '#3e98c7' }}
              onClick={() => ContactView(row, 'ContactView')}
            >
              info
            </span>}
            {AppPermission.click_to_call && <span
              className={stateValue.background.oncall == false && wasession === "false" && autodialstatus ? 'material-icons' : "material-icons disabledbutton"}
              style={{ color: '#32AA52' }}
              onClick={() => clickcall(row)}
            >
              phone
            </span>}
            {AppPermission.wa_view &&
              <img
                onClick={() => { wapopup(row.original) }}
                className={"wa_icon"}
                src={waicon}
                alt="test"
              />


            }
          </div>
        )
      },
    }
  }
  useEffect(() => {
    mountedfunction()

    if(AppPermission.wa_view){
    dispatch(getTemplate()).then((res) => {
      if (res.data.length > 0) {

        setpopUpData((set) => {
          set.field[0].option = res.data.map(e=>{
            e.label = e.name
            e.value = e.id
            return e
          })
          return {
            ...set
          }
        })
      }
    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'fetch',
        module: "whatsapp Template",
      })
    })
    }
  }, [])
  // edit
  const handleSettingsClick = (value)=>{
    setDynamicHead(!value)
  }
  const saveDynamicPopup = (data) => {
    if(data===false){
      setDynamicHead(data)
      return
    }
    setDynamicHead(false)
    setloader(true)
    const payload ={
      fields:[data],
      module:'contact',
      campaign:selected_campaign,
      template:campaigninfo.template_name
    }
    dispatch(AsterAgentTableUpdate(payload,agent)).then((response)=>{
      if(response.data.statusCode === 200){
        getDynamicheaders()
      }
    }).catch((err)=>{
      setloader(false)
    })
  }
  useEffect(()=>{
    getDynamicheaders()
  },[])
  function getDynamicheaders(){
    setloader(true)
    const data = {
      user:agent,
      campaign:selected_campaign,
      module:'contact',
      template:campaigninfo.template_name
    }
    
    dispatch(AsterAgentTableField(data)).then((response)=>{
      if(response.data.statusCode === 200){
        const headerDefault = checkheaderAction();
        const defaultHeader =[headerDefault]
        const newData = response.data.data.map(item => {
          if(item.model === 'phone_number'){
            return{
              masking:true,
              Header: item.label,
              accessor:item.model, 
              agent_table_show:item.agent_table_show
            }
          }else{
            return{
              masking:false,
              Header: item.label,
              accessor:item.model, 
              agent_table_show:item.agent_table_show
            }
          }

          });
        const updatedHeader = [...newData,...defaultHeader];
        setheader(updatedHeader) 
        setloader(false)
      }else{
        const headerDefault = checkheaderAction();
        const defaultHeader = [headerDefault];
        setheader(defaultHeader)
        setloader(false)
      }
      refresh()
    }).catch((err)=>{
      EventEmitter.emit('error-message-getter', {
        action: 'field',
        module: "Selected fields are removed Please select field.",
      })
      setloader(false)
    })
  }
  // edit

  function mountedfunction(action) {
    let url
    if (action === "refresh") {
      setinfinitScroll((set) => {
        set.more = true
        return {
          ...set,
        }
      })
      setsearchContactValue("")
      url = `/contact/fetchsearch?skip=${0}&take=${20}`
    }
    else if (searchContactValue !== "") {
      url = `/contact/fetchsearch?skip=${skipdata.skip}&take=${skipdata.take}&value=${searchContactValue}`
    }
    else {
      url = `/contact/fetchsearch?skip=${skipdata.skip}&take=${skipdata.take}`
    }
    if(action !="Moredata"){
      setloader(true)
    }
    dispatch(ContactFetch(url))
      .then((response) => {
        setloader(false)
        if (response.status === 200) {
          setcount(response.data.count)
          setskipdata((set) => {
            if (set.take === 20) {
              set.skip = 20
              set.take = 10
            } else {
              set.skip = set.skip + 10
              set.take = 10
            }

            return {
              ...set,
            }
          })
          let element = response.data.data
          let value = []
          for (let i = 0; i < element.length; i++) {
            let obj = {};
            for (let key in element[i]) {
            if (element[i].hasOwnProperty(key)) {
              element[i][key]=element[i][key] === null ? "" :element[i][key]
              if (typeof element[i][key] !== 'object') {
              obj[key] = reduceLength(Format(element[i][key]));
              }
            }
           }
            obj.all = element[i];
            value.push(obj);
          }
          if (action === "refresh") {
            setcontactDetails([].concat(value))
          } else {
            setcontactDetails(contactDetails.concat(value))
          }

        } else {
          setcontactDetails([])
        }
      })
      .catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'contact',
        })
      })
  }
  function isValidDateString(str) {
    return !isNaN(Date.parse(str));
  }
  const reduceLength = (value) =>{
    if (typeof value === 'string' && isValidDateString(value)) {
      let datetime = new Date(value)
      let dd = datetime.getDate() < 10 ? `0${datetime.getDate()}` : datetime.getDate()
      let mm = datetime.getMonth() + 1 < 10 ? `0${datetime.getMonth() + 1}` : datetime.getMonth() + 1
      let hr = datetime.getHours() < 10 ? `0${datetime.getHours()}` : datetime.getHours()
      let min = datetime.getMinutes() < 10 ? `0${datetime.getMinutes()}` : datetime.getMinutes()
      value = datetime.getFullYear() + `-${mm}-${dd} ${hr}:${min}`
    }
    else if(typeof value=== "string" && value.length >= 17 ){
      value = value.substring(0, 17)+'...';
      return value
    }
    return value
  }
  const ContactView = (row, pageName) => {
    if (pageName === 'ContactView') {
      navigate(`/contact/${Encode(row.original.phone_number)}/${row.original.id}`)
    }
  }

  const createContact = () => {
    navigate(`/contact/create`)
  }


  const filterdataapi = (data, isfilter) => {

  }

  function searchcontact(e) {
    setinfinitScroll((set) => {
      set.more = true
      return {
        ...set,
      }
    })
    setskipdata((set) => {
      set.search = e.target.value
      set.skip = 20
      set.take = 10
      return {
        ...set,
      }
    })
    setsearchContactValue(e.target.value)

    let url

    url = `/contact/fetchsearch?skip=${0}&take=${20}&value=${e.target.value}`
    setloader(true)
    dispatch(ContactFetch(url))
      .then((response) => {
        setloader(false)
        if (response.status === 200) {
          setcount(response.data.count)
          let element = response.data.data
          let value = []
          for (let i = 0; i < element.length; i++) {
            let obj = {};
            for (let key in element[i]) {
            if (element[i].hasOwnProperty(key)) {
              element[i][key]=element[i][key] === null ? "" :element[i][key]
              if (typeof element[i][key] !== 'object') {
              obj[key] = reduceLength(Format(element[i][key]));
              }
            }
           }
             obj.all = element[i];
            value.push(obj);
        }
          setcontactDetails([].concat(value))
        } else {
          setcontactDetails([])
        }
      })
      .catch((err) => {
        EventEmitter.emit('error-message-getter', {
          action: 'fetch',
          module: 'contact',
        })
      })
  }

  let fetchMoreData = () => {
    if (contactDetails.length >= count) {
      setinfinitScroll((set) => {
        set.more = false
        return {
          ...set,
        }
      })
    } else {
      setTimeout(() => {
        mountedfunction("Moredata")
      }, 1000)
    }
  }


  function clickcall(data) {

    let readystate = localStorage.getItem("selectedPause")

    if (readystate == "available") {
      let payload = {
        state: stateValue.background.websocketconnection,
        value: data.original.phone_number.toString()
      }

      dispatch(callmodule({
        id: data.original.id.toString(),
        module: "contact",
        phone_no: data.original.phone_number.toString()
      }))
      dispatch(Outbound(payload.state, payload.value))

    } else {

      EventEmitter.emit('error-message-getter', {
        action: 'ready',
        module: "Agent Not ready",
      })
    }

  }
  // {
  //   Header: "phone_number",
  //   accessor: "phone_number",
  //   agent_table_show: 1,
  //   color:true
  // },

  const refresh = () => {
    mountedfunction('refresh')
    setskipdata((set) => {
      set.skip = 0
      set.take = 20
      return {
        ...set,
      }
    })
    setinfinitScroll((set) => {
      set.more = true
      return {
        ...set,
      }
    })
  }
  function wapopup(data) {
    setwadata(data)
    setpopUpData((set) => {
      set.open = true
      return {
        ...set
      }
    })
  }
  function closepopup(data, name) {
    if (data.action === "cancel") {
      setpopUpData((set) => {
        set.open = false
        return {
          ...set
        }
      })
    } else {
      function find_(data_) {
        return data_.value === data.data[0].whatsapp_template;
      }


      let templatedata = {
        "action": "send-wa-template",
        "station": localStorage.getItem('extension'),
        "phone_number": wadata.phone_number.toString(),
        "sender_id": wadata.id,
        "sender_type": 1,
        "sender_name": wadata.name,
        "tenant_id": JSON.parse(localStorage.getItem("config")).data.tenant_code,
        "agent": localStorage.getItem("user"),
        "template": {
          "id": data.data[0].whatsapp_template,
          "type": "",
          "name": data.field[0].option.find(find_).name,
          "language": data.field[0].option.find(find_).lang
        }
      }
      if (data.action === "create") {
        dispatch(waTemplate(stateValue.background.websocketconnection, templatedata))
        setpopUpData((set) => {
          set.open = false
          return {
            ...set
          }
        })
      }


     
    }
  }
  const structureChange = () =>{
    if(header.length <= 4 || loader){
      return ''
    }
    return 'structureChange'
  }
  return (
    <div className="page-main-container page-main-container-mobile">
      <DynamicField
      module={'contact'}
      dynamicHead={dynamicHead}
      saveDynamicPopup={saveDynamicPopup}
      />
      <Dialogbox
        fields={closepopup}
        value={popUpData}
        className="dialog-body-default1"
        // cancel={{ display: 'none' }}
        save={'disabled'}
      />
      <div className="scroll-lft" md={8}>


        <div className="lead-header">

          <div className="lead-header1">
          <div className="route-header lead-header1-lft" style={{cursor: 'default'}}> Contacts</div>
            {AppPermission.contact_search && <div className="lead-search">
              <span className="material-icons lead-search-icon">search</span>
              <Form.Control
                onChange={(e) => {
                  searchcontact(e)
                }}
                type="text"
                value={searchContactValue}
                placeholder="Search"
                style={{
                  border: '1px solid #e9ecef',
                  backgroundColor: '#fff',
                  padding: 10,
                  'padding-left': '40px',
                }}
              />
            </div>}
          </div>
          <div className="lead-header2">
          {AppPermission.contact_refresh && 
           <OverlayTrigger
           placement={'bottom'}
           overlay={<Tooltip>{'Refresh'}</Tooltip>}
         >
           <div
             onClick={refresh}
             className="material-icons lead-table-button"
           >
             refresh
           </div>
         </OverlayTrigger>
         }
          <OverlayTrigger
                placement={'bottom'}
                overlay={<Tooltip>Header settings</Tooltip>}
              >
          <div className="lead-header2">
           <span
            className="material-icons settings lead-table-button"
            style={{ color: '#3e98c7' }}
            onClick={() => handleSettingsClick(dynamicHead)}
           >
            settings
           </span>
          </div>
          </OverlayTrigger>

            {AppPermission.contact_filter && <Filters filterdataapi={filterdataapi} type={'contacts'} />}
            {adminPermission('leadmanagement:contacts:create')&&AppPermission.contact_create && 
            <OverlayTrigger
            placement={'bottom'}
            overlay={<Tooltip>{'Add'}</Tooltip>}
          >
            <div
              onClick={createContact}
              className="material-icons lead-table-button" 
            >
              add_circle
            </div></OverlayTrigger>}
          </div>
        </div>
        <div className="lead-body">
          <div
          className={structureChange()}   
            id="ticketDiv"
            style={{
              height: '100%',
              overflow: 'auto',
              color: 'grey',
              textAlign: 'center',
              width: '100%',
            }}
          >
            <InfiniteScroll
              dataLength={contactDetails.length}
              next={fetchMoreData}
              hasMore={infinitScroll.more}
              scrollableTarget="ticketDiv"
            >
              <ContactTable
                loader={loader}
                checkbox={false}
                header={header}
                leaddetail={contactDetails}
                type="lead"
                maskphoneindex={maskphoneindex}
              />
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contacts
