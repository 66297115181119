import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector, connect } from 'react-redux'
import '../assets/css/profile.css'
import { Subdispo } from '../redux/actions/AsterCallAction'
import { BsFillHexagonFill } from 'react-icons/bs'
import moment from 'moment'
import Format from '../utils/format-text'
import { CallActivity, Getdispo, GetSubdispo } from '../redux/actions/AsterCallAction'
import Dialogbox from './Dialogbox'
import {  triggerdispostate, socketmessage, callLeadDetail } from '../redux/background/BackgroundAction'
import EventEmitter from '../utils/EventEmitter'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { Encode } from '../utils/EncodeDecode'
import { PopupRouter } from '../redux/actions/Customform'
import { Meetingtitle, create_meeting, Reassign_User } from '../redux/actions/Meetings'
import { setlocalstorage, getlocalstorage, removelocalstorage } from '../utils/localstorage-provider'
import { forcelogout } from '../redux/SocketAction/SocketAction'
function CallDispo(props) {
  let navigate = useNavigate()

  let [selectedshowDispo, setselectedshowDispo] = useState('')

  let campaigndetailslocalstorage = JSON.parse(localStorage.getItem("campaigndetails"))


  let selected_campaign = localStorage.getItem('selectedCampaign')
  let vaidate = localStorage.getItem("formvalidate")
  const [shedulealart, setshedulealart] = useState(false)
  let wasession = JSON.parse(localStorage.getItem('wasession'))
  const dValue = JSON.parse(localStorage.getItem('oncalldata'))
  const callValue = JSON.parse(localStorage.getItem('callstatus'))
  let customformdata = localStorage.getItem('customformdata')
  let Dispatch = useDispatch()
  let process = localStorage.getItem('process')
  let AppPermission
  if (localStorage.getItem('config')) {
    AppPermission = JSON.parse(localStorage.getItem('config')).data.projects[0].userPermission
  }
  let campaigninfo = JSON.parse(localStorage.getItem("selectedCampaign_data"))
  const user = localStorage.getItem('user')
  let config = JSON.parse(localStorage.getItem('config'))
  let session_state = useSelector((state) => state)
  const [dispo, setdispo] = useState(JSON.parse(localStorage.getItem('dispo')))
  let [autodialtimer, setautodialtimer] = useState(0)
  let [dispo_subdispo, setdispo_subdispo] = useState({})
  const [meetingdata, setmeetingdata] = useState({
    open: false,
    Type: 'form_bar',
    field: [
      {
        type: 'SelectList',
        label: 'Meeting Title',
        placeholder: 'please select meeting title',
        required: true,
        model: 'name',
        option: [],
        multiple: false,
        value: '',
        readonly: false,
      },
      {
        type: 'TextInput',
        label: 'others',
        placeholder: 'enter Meeting Name',
        required: false,
        model: 'others',
        value: "",
        readonly: false,
        multiple: false,
        fieldtype: 'others',
        model_type: "Text",
        show: false
      }
      , {
        type: 'SelectList',
        label: 'Meeting Sub Title',
        placeholder: 'please select meeting sub title',
        required: false,
        model: 'meeting_sub_title',
        option: [],
        multiple: false,
        value: '',
        readonly: false,
        show: false
      },
      {
        type: 'TextInput',
        label: 'Phone Number',
        placeholder: Format(session_state.background.oncalllead[0].phone_number?.toString() || session_state.background.socket_message.phonenumber?.toString(), { maskbool: true }),
        required: true,
        model: 'meetingphoneNumber',
        // value: session_state.background.oncalllead[0].phone_number.toString(),
        readonly: true,
        multiple: false,
        fieldtype: 'phone_number',
        model_type: "Number"
      }, 
      {
        type: 'SelectList',
        label: 'Module',
        placeholder: 'Module',
        required: true,
        model: 'module',
        option: [],
        multiple: false,
        value: '',
      },
      {
        type: 'DatetimePicker',
        label: 'Schedule Date',
        placeholder: 'please select',
        required: true,
        allow_previousdate: true,
        model: 'scheduleDate',
        multiple: true,
        value: moment(datetime()).add(campaigninfo.meeting_interval, 'm').toDate(),
      },
      {
        type: 'LongTextInput',
        label: 'Description',
        placeholder: 'enter your comments',
        required: true,
        model: 'comments',
        value: '',
        multiple: false,
      },
    ],

    title: 'Create Meeting',
    Message: 'Created Successfully ',
    Butonstatus: 'Create',
  })
  const [popupdata, setpopupdata] = useState({
    open: false,
    Type: 'Notification_bar',
    field: [],
    title: 'Select Disposition ',
    Message: 'Select alteast one disposition !!! ',
  })
  let seconds = parseInt(localStorage.getItem("callcount"));
  const location = useLocation()
  const [ActiveDispo, setActiveDispo] = useState([])
  const [selectedDispo, setselectedDispo] = useState('')
  const [selectedDispo1, SetSelectedDispo1] = useState('')
  const [selectedSubDispo, setselectedSubDispo] = useState('')
  const [subDispo, setsubDispo] = useState([])
  const [dispobutton, setdispobutton] = useState(false)
  const [savedispo, setsavedispo] = useState(false)
  const [showsubdispo, setShowsubdispo] = useState(false)
  let error_popup = localStorage.getItem('erroraction_popup')





  const [cfsavepopup, setcfsavepopup] = useState({
    open: false,
    Type: 'Notification_bar',
    field: [],
    title: 'Add Customer',
    Message: 'Customer has not been added!!',
  })
  useEffect(() => {
    if(Object.keys(session_state.background.socket_message).length === 0){
      let wa_session_data = JSON.parse(localStorage.getItem('wa-session-data'))
      Dispatch(socketmessage(wa_session_data));
    }
    fetchmeetingTitle()
    let array = []
    for (let i = 0; i < dispo.length; i++) {
      const element = dispo[i]
      let obj = {}
      obj[`${element}`] = false
      array.push(obj)
    }
    setActiveDispo(array)


    let { hangup_seconds, default_dispo } = config.data.projects[0].config[0]
    var timeout = null
    var interval = null
    let count_down = 0
    if (AppPermission.countdown_dispo) {
      setdispo_subdispo({ dispo: campaigndetailslocalstorage.auto_dispo_value, subdispo: campaigndetailslocalstorage.auto_dispo_value })
      // let dispocount_down = localStorage.getItem("countdown_dispo")
      let dispocount_down = getlocalstorage("countdown_dispo")
      let network_status = session_state.background.networkstatus

      if (dispocount_down == null) {
        count_down = hangup_seconds
        setautodialtimer(hangup_seconds)
        // localStorage.setItem("countdown_dispo",hangup_seconds)
        setlocalstorage("countdown_dispo", hangup_seconds)
      }
      else {
        count_down = dispocount_down
        setautodialtimer(dispocount_down)
      }
      interval = setInterval(() => {
        setautodialtimer((seconds) => {
          let sec
          if (seconds !== 0) {
            sec = seconds - 1
            // localStorage.setItem("countdown_dispo",sec)
            setlocalstorage("countdown_dispo", sec)

          } else if (seconds === 0) {
            if (network_status == "offline") {
              sec = 0
            } else {
              clearInterval(interval)
              sec = 0
            }
            // sec = hangup_seconds


            // localStorage.removeItem("countdown_dispo")
            removelocalstorage("countdown_dispo")
          }
          return sec
        })
      }, 1000)


      timeout = setTimeout(() => {
        removelocalstorage("countdown_dispo")
        
        // triggerdispo(true)
        save(campaigndetailslocalstorage.auto_dispo_value, undefined)
      }, count_down * 1000)
    }
    return () => {
      clearTimeout(timeout)
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    // event()

  }, [])

  useEffect(() => {
    if (props.onCallFunction) {
      handleFunctionCall();
      props.returnonCallFunction()
    }
  }, [props.onCallFunction]);


  const handleFunctionCall = () => {
    let test = JSON.parse(localStorage.getItem('test'))
    showDispofunction(undefined, test)
    console.log("CallScreen function called");
  };


  function datetime() {
    var currentdate = new Date().toISOString()
    return currentdate
  }
  function fetchmeetingTitle() {
    Dispatch(Meetingtitle()).then(function (res) {
      if (res.data.statusCode == 200) {
        let arr = []
        for (let i = 0; i < res.data.data.length; i++) {
          const e = res.data.data[i];
          arr.push({
            label: e.title,
            value: e.title,
            subtitle: e.sub_title
          })
        }
        arr.push({
          label: "others",
          value: "others"

        })
        setmeetingdata((set) => {
          set.field[0].option = arr
          return set
        })


      }
    })
  } function addLeadingZero(number) {
    return number < 10 ? `0${number}` : number;
  }



  function savecustform() {
    setcfsavepopup((data) => {
      data.open = false
      return {
        ...data,
      }
    })
  }



  function closepopup() {
    setpopupdata((data) => {
      data.open = false
      setsavedispo(false)
      return {
        ...data,
      }
    })
  }
  const isshow = (data, value) => {
    let subtitle = []
    if (value !== 'others') {
      for (let i = 0; i < meetingdata.field[0].option.length; i++) {
        const element = meetingdata.field[0].option[i];
        if (element.label === value) {
          let subtitlearr = element.subtitle.split(',')
          for (let j = 0; j < subtitlearr.length; j++) {
            const subtitleelement = subtitlearr[j];
            subtitle.push({
              label: subtitleelement,
              value: subtitleelement,
            })

          }
        }

      }
    }
    setmeetingdata((set) => {
      set.field[1].show = data
      set.field[1].required = data
      if (value == 'others') {
        set.field[2].show = false
      } else {

        if (subtitle.length > 0 && subtitle[0].label !== '' && subtitle[0].value !== '') {
          set.field[2].show = true
        } else {
          set.field[2].show = false
        }

      }
      set.field[2].option = subtitle
      return set
    })


  }
  const meetingdialogopen = (data) => {
    setmeetingdata((set) => {
      set.open = true
      set.dispo = data
      return {
        ...set,
      }
    })
  }
  const meetingclosepopup = (data) => {
    if (data.action === "create") {
      let payload = {
        meetingName: data.data[0]['name'] === 'others' ? data.data[0].others : data.data[0]['name'],
        meetingTilte: data.data[0]['comments'],
        meetingTime: data.data[0]['scheduleDate'],
        user_email: localStorage.getItem('user'),
        leadID: data.data[0]['id'].toString(),
        phoneNumber: session_state.background.oncalllead[0].phone_number.toString() || session_state.background.socket_message.phonenumber.toString(),
        meeting_sub_title: data.data[0]['meeting_sub_title'],
        campaign: selected_campaign,
        module: data.data[0]['module'],
        callerName: session_state.background.oncalllead[0].phone_number.toString() || session_state.background.socket_message.phonenumber.toString(),
      }
      Dispatch(create_meeting(payload))
        .then(function (res) {
        
          if (res.data.statusCode == 201) {
            EventEmitter.emit("error-message-getter", {
              action: "createform",
              module: "meeting",
            });
            setmeetingdata((data_) => {
              data_.open = false
              if (data.dispo.main) {
                console.log("iiiiiiiiiiiiiiiiiiiiiii","ppppppppppppp")
                triggerdispo(false, data.dispo.data, '')
              } else if (data.dispo.sub) {
                console.log("iiiiiiiiiiiiiiiiiiiiiii","qqqqqqqqqqqqqq")
                triggerdispo(false, selectedDispo, data.dispo.data)
              }
              return {
                ...data,
              }
            })
          }

        })
        .catch((err) => {

          EventEmitter.emit('error-message-getter', {
            action: 'create',
            module: 'meeting',
          })
        })
    } else {
      setmeetingdata((data) => {
        data.open = false
        return {
          ...data,
        }
      })
    }

  }


  async function back2form(type) {
    let url = ''

    try {

       let payload = {
      template_name: campaigninfo.template_name,
      phone_number: session_state.background.socket_message.phonenumber
    }
         if (process === "leads" || process === "leads,meetings" || process === "leads,tickets,meetings" || process === "leads,tickets") {
        payload.module = 'leads'
        payload.lead_id = session_state.background.socket_message.lead_id;
        const res = await Dispatch(PopupRouter(payload))

        if (res.data.ids.length > 0) {
          let id = res.data.ids[0].lead_id
          
            url = `/leads/${Encode(session_state.background.socket_message.phonenumber)}/${id}`
         
            navigate(`/leads/${Encode(session_state.background.socket_message.phonenumber)}/${id}`)
          

        } else {
          
            url = `/leads/create`
         
            navigate('/leads/create')
          
        }
      } else if (process === "tickets" || process === "tickets,meetings") {
        payload.module = 'tickets'
        const res = await Dispatch(PopupRouter(payload))

        if (res.data.ids.length > 0) {
          let id = res.data.ids[0].ticket_id
         
            url = `/tickets/${Encode(session_state.background.socket_message.phonenumber)}/${id}`
          
            navigate(`/tickets/${Encode(session_state.background.socket_message.phonenumber)}/${id}`)
          
        } else {
          
            url = `/tickets/create`
         
            navigate('/tickets/create')
          
        }
      } else if (process === "meetings") {
        payload.module = 'meetings'
        const res = await Dispatch(PopupRouter(payload))

        if (res.data.ids.length > 0) {
          let id = res.data.ids[0].fwp_id
          
            url = `/meeting/${Encode(session_state.background.socket_message.phonenumber)}/${id}`
        
            navigate(`/meeting/${Encode(session_state.background.socket_message.phonenumber)}/${id}`)
          
        } else {
          if (res.data.contact_id.length > 0) {
            
              url = `/contact/${Encode(session_state.background.socket_message.phonenumber)}/${res.data.contact_id[0].id}`
           
              navigate(`/contact/${Encode(session_state.background.socket_message.phonenumber)}/${res.data.contact_id[0].id}`)
            
          } else {
           
              url = `/contact/create`
           
              navigate('/contact/create')
            
        }
      }
      }
      props.popupOpen()
      if (type === "return") {
        return url
      }

    } catch (err) {
      console.log(err,"err");
      EventEmitter.emit('error-message-getter', {
        action: 'fetch',
        module: 'popup router',
      })
    }


  }












  function showDispofunction(data, eventdata) {
    if (selectedDispo !== '' || data != undefined || eventdata.selectedDispo !== "") {
      if ((props.type !== "call")) {
        let dispo
        if (eventdata !== undefined) {
          dispo = [
            eventdata.selectedDispo, eventdata.selectedSubDispo
          ]
        } else {
          dispo = [
            selectedDispo, selectedSubDispo
          ]
        }

        props.dispodata([session_state.background.websocketconnection, dispo])
        // props.closedispo(true)
      }
      else if ((props.type === "call" || customformdata === 'false')) {


        let callEndTime
        if (session_state.background.socket_message.endtime === '') {
          var date = moment()
          var currentDate = date.format('YYYY-MM-DD')
          var CurrentDateValue = new Date() // for now
          var time = `${CurrentDateValue.getHours()}:${CurrentDateValue.getMinutes()}:${CurrentDateValue.getSeconds()}`
          callEndTime = `${currentDate} ${time}`
        } else {
          callEndTime = session_state.background.socket_message.endtime
        }

        let duration
        let hours = Math.floor(seconds / 3600 % 60)
        let minites = Math.floor(seconds / 60 % 60)
        let sec = Math.floor(seconds / 1 % 60)
        if (hours < 10) hours = '0' + hours
        if (minites < 10) minites = '0' + minites
        if (sec < 10) sec = '0' + sec
        let obj = { evt: "endcall", duration: `${hours}:${minites}:${sec}` }
        Dispatch(socketmessage(obj))
        duration = `${hours}:${minites}:${sec}`

        let payload
        if (callValue !== null && callValue !== undefined) {
          var date_ = moment()
          var currentDatereload = date_.format('YYYY-MM-DD')
          var CurrentDateValuereload = new Date() // for now
          var timereload = `${addLeadingZero(CurrentDateValuereload.getHours())}:${addLeadingZero(CurrentDateValuereload.getMinutes())}:${addLeadingZero(CurrentDateValuereload.getSeconds())}`;

          var callEndTimereload = `${currentDatereload} ${timereload}`

          if (callValue.ringing_time === "" || callValue.ringing_time === undefined) {
            callValue.ringing_time = callEndTimereload
          }
          if (callValue.calltype === "" || callValue.calltype === undefined) {
            callValue.calltype = "OUTBOUND"
          }
          payload = {
            subject: callValue.subject,
            callType: callValue.calltype,
            campaign: localStorage.getItem('selectedCampaign'),
            leadModule: dValue.module ? dValue.module : `${session_state.background.oncalllead[0].module}`,
            leadID: `${dValue.lead_id}` ? `${dValue.lead_id}` : `${session_state.background.oncalllead[0].lead_id}`,
            module: dValue.module ? dValue.module : session_state.background.oncalllead[0].module,
            ownerID: localStorage.getItem('user'),
            ownerName: localStorage.getItem('username'),
            callStartTime: callValue.ringing_time,
            phoneNumber: callValue.phonenumber,
            duration: duration,
            lead_name: dValue.lead_name ? dValue.lead_name : session_state.background.oncalllead[0].lead_name,
            callResult: eventdata !== undefined ? { name: eventdata.selectedDispo, value: eventdata.selectedDispo } : selectedDispo != "" ? { name: selectedDispo, value: selectedDispo } : data === undefined || data === null ? { name: "", value: "" } : { name: data.dispo, value: data.dispo },
            callDescription: '',
            callEndTime: callEndTimereload,
            uniqueID: callValue.uniqueid,
            SubcallResult: eventdata !== undefined ? { name: eventdata.selectedSubDispo, value: eventdata.selectedSubDispo } : selectedSubDispo != "" ? { name: selectedSubDispo, value: selectedSubDispo } : data === undefined || data == null ? { name: "", value: "" } : { name: data.subdispo, value: data.subdispo },
            channel: 'call',
            channel_id: callValue.uniqueid,
          }
        }
        else {
          payload = {
            subject: session_state.background.socket_message.subject,
            callType: session_state.background.socket_message.calltype,
            campaign: props.campaign,
            leadModule: '',
            leadID: `${session_state.background.oncalllead[0].lead_id}`,
            module: session_state.background.oncalllead[0].module,
            ownerID: user,
            ownerName: props.user,
            callStartTime: props.ringing_time,
            phoneNumber: session_state.background.socket_message.phonenumber.toString(),
            duration: duration,
            lead_name: session_state.background.oncalllead[0].lead_name,
            callResult: eventdata !== undefined ? { name: eventdata.selectedDispo, value: eventdata.selectedDispo } : selectedDispo != "" ? { name: selectedDispo, value: selectedDispo } : data === undefined || data === null ? { name: "", value: "" } : { name: data.dispo, value: data.dispo },
            callDescription: '',
            callEndTime: callEndTime,
            uniqueID: session_state.background.socket_message.uniqueid,
            SubcallResult: eventdata !== undefined ? { name: eventdata.selectedSubDispo, value: eventdata.selectedSubDispo } : selectedSubDispo != "" ? { name: selectedSubDispo, value: selectedSubDispo } : data === undefined || data == null ? { name: "", value: "" } : { name: data.subdispo, value: data.subdispo },
            channel: 'call',
            channel_id: session_state.background.socket_message.uniqueid,
          }
        }

        Dispatch(CallActivity(payload)).then((response) => {
          if (response.status === 201) {
            let leadData = {
              lead_id: "",
              lead_name: "",
              phone_number: "",
              module: "",
              render: 0
            }
            let maindispo = eventdata !== undefined ? eventdata.selectedDispo : selectedDispo != "" ? selectedDispo : data === undefined || data === null ? "" : data.dispo
            let subdispo = eventdata !== undefined ? eventdata.selectedSubDispo : selectedSubDispo != "" ? selectedSubDispo : data === undefined || data == null ? "" : data.subdispo
            if (session_state.background.oncalllead[0].module === "lead") {
              SelectdispoApi(maindispo, true, false, true, {
                "modules": "LEAD",
                "actions": "REASSIGN",
                "payload": {
                  "team": maindispo,
                  "template": campaigninfo.template_name,
                  "data": {

                    "lead_id": `${session_state.background.oncalllead[0].lead_id}`
                  }
                }
              })
              SelectdispoApi(subdispo, false, true, true, {
                "modules": "LEAD",
                "actions": "REASSIGN",
                "payload": {
                  "team": subdispo,
                  "template": campaigninfo.template_name,
                  "data": {

                    "lead_id": `${session_state.background.oncalllead[0].lead_id}`
                  }
                }
              })
            }


            Dispatch(callLeadDetail(leadData))
            props.closedispo(true)
            setdispobutton(false)
            let dispo
            if (selectedDispo !== "") {
              dispo = [
                { name: selectedDispo, value: selectedDispo },
                { name: selectedSubDispo, value: selectedSubDispo },
              ]
            } else if (eventdata !== undefined) {
              dispo = [
                { name: eventdata.selectedDispo, value: eventdata.selectedDispo },
                { name: eventdata.selectedSubDispo, value: eventdata.selectedSubDispo },
              ]
            }
            else {
              if (data === undefined || data === null) {
                dispo = [
                  { name: "", value: "" },
                  { name: selectedSubDispo, value: selectedSubDispo },
                ]
              } else {
                dispo = [
                  { name: data.dispo, value: data.dispo },
                  { name: selectedSubDispo, value: selectedSubDispo },
                ]
              }
            }
            props.dispodata([session_state.background.websocketconnection, dispo])
            Dispatch(triggerdispostate(false))
            localStorage.removeItem("waformdata")
            localStorage.removeItem("oncalldata")
            localStorage.removeItem("callstatus")
            localStorage.removeItem("showdispo")
            removelocalstorage("countdown_dispo")
           
          }
        })
          .catch((err) => {
            EventEmitter.emit('error-message-getter', {
              action: 'create',
              module: "activity",
            })
          })
      }
    } else {
      setpopupdata((data) => {
        data.open = true
        return {
          ...data,
        }
      })
    }
  }






  async function selectDispo(data, savedispo) {
    if (savedispo) {
      await setselectedDispo((set) => {
        return data
      })
      await save(data, undefined)
      //  
    } else {
      getsubdispo(data)
      await setselectedDispo(data)
      if (selectedshowDispo === data) {
        setselectedshowDispo('')
        setShowsubdispo(true)
      } else {
        setselectedshowDispo(data)
        setShowsubdispo(false)
      }
    }
  }


  async function save(dispo, subdispo) {
    try {
      // Wait for the back2form function to resolve
      let currentpath = location.pathname
      let urlpath = await back2form('return');
      let urlpath_array=urlpath.split('/')
      let currentpath_array=currentpath.split('/')
      if (currentpath.split('/').length>2 &&urlpath_array.some(word => currentpath_array.includes(word))) {
       if (subdispo === undefined) {
          SelectdispoApi(dispo, true,false,false);
        } else {
          SelectdispoApi(subdispo, false,true,false);
        }
      }else{
        await back2form();
        if (subdispo === undefined) {
          SelectdispoApi(dispo, true,false,false);
        } else {
          SelectdispoApi(subdispo, false,true,false);
        }
      }

    } catch (error) {
      console.error('Error in save function:', error);
    }
  }


  function getsubdispo(data) {
    setsubDispo([])
    Dispatch(Subdispo(data)).then(function (value) {
      let array = []
      if (value.status === 200) {
        for (let i = 0; i < value.data.data.length; i++) {
          const element = value.data.data[i]
          array.push(element.subdispo_id)
        }
        setsubDispo(array)
      }
    }).catch((err) => {
      EventEmitter.emit('error-message-getter', {
        action: 'fetch',
        module: 'subdispo',
      })
    })
  }
  function triggerdispo(data, dispo, subdispo = '') {
    
    if (dispo !== '' || data !== undefined) {
      // EventEmitter.emit('validatedispo', {
      //   selectedDispo:dispo,selectedSubDispo:subdispo
      // })
      localStorage.setItem('test',
        JSON.stringify({
          selectedDispo: dispo, selectedSubDispo: subdispo
        })
      )
      props.validatedispo()
      // Dispatch(dispovalidation_data({
      //     selectedDispo:dispo,selectedSubDispo:subdispo
      //   }))

    } else {
      setpopupdata((data) => {
        data.open = true
        return {
          ...data,
        }
      })
    }

  }

  function SelectdispoApi(payload, maindispo, subdispo, afterAction, others) {

    if (maindispo) {
      Dispatch(Getdispo(payload)).then((response) => {
        
        if ((afterAction === false || afterAction === undefined) && response[0].action === "Meeting") {
          meetingdialogopen({ main: true, sub: false, data: payload })


        } else if (afterAction && response[0].action === "Reassign") {
          Dispatch(Reassign_User(others)).then((response) => {

          })
        } else if(afterAction===false){
          console.log("iiiiiiiiiiiiiiiiiiiiiii","rrrrrrrrrrrrrr")
          triggerdispo(false, payload)

        }


      })
    } else {
      Dispatch(GetSubdispo(payload)).then((response) => {
       
        if ((afterAction === false || afterAction === undefined) && response[0].action === "Meeting") {
          meetingdialogopen({ main: false, sub: true, data: payload })

        } else if (afterAction && response[0].action === "Reassign") {
          Dispatch(Reassign_User(others)).then((response) => {

          })
        } else if(afterAction===false) {
          console.log("iiiiiiiiiiiiiiiiiiiiiii","ssssssssssssssssssss")
          triggerdispo(false, selectedDispo, payload)
        }

      })
    }

  }
  function subdisposelect(subdisp) {
    
    setselectedSubDispo(subdisp)
    save(selectedDispo, subdisp)

  }


  return (
    <>
      <div className="dispo_container">
        <div className="dispo_head">

          <div className="dispo_head_lft">{Format(props.type)} Disposition</div>
          {AppPermission.countdown_dispo && <h2 className="lead-count ">{autodialtimer}</h2>}
          {localStorage.getItem("wasession") === "false" && <div
            className="material-icons dispo_save"
            onClick={() => back2form()}
            style={{ "padding-right": "10px" }}
          >
            description
          </div>}
          {/* <div
            // className={session_state.background.oncalllead[0].render === 1 || localStorage.getItem("wasession") === "true" ? "material-icons dispo_save" : "disabledbutton material-icons dispo_save"}
            className={"material-icons dispo_save"}
            // onClick={() => triggerdispo(false)}
            onClick={() => back2form("save")}
          >
            save
          </div> */}

        </div>
        <div className="dispo_body">
          {dispo.map((dispoValue, i) => {
            return (
              <div className="dispo_body_container">
                <div
                  className="dispo_body_container_first"
                  style={
                    selectedDispo == dispoValue
                      ? { backgroundColor: 'rgb(238 246 252)' }
                      : { backgroundColor: '#fff' }
                  }

                >

                  <div className="dispo_body_container_first_sub_icon">
                    <BsFillHexagonFill />
                  </div>
                  <div
                    className="dispo_body_container_first_sub"

                  >
                    {dispoValue.toUpperCase().replace('_', ' ')}
                  </div>
                  {<div
                    className="material-icons dispo_save"

                    style={{ "padding-right": "10px" }}
                    onClick={() => selectDispo(dispoValue, true)}
                  >

                    check
                  </div>}
                  <div
                    className="material-icons dispo_save"
                    style={{ "padding-right": "10px" }}
                    onClick={() => selectDispo(dispoValue, false)}
                  >
                    swap_vert
                  </div>

                </div>
                {/* {JSON.stringify(selectedDispo1)}{JSON.stringify(dispoValue)} */}
                {(selectedshowDispo == dispoValue && showsubdispo === false) && (
                  <div>
                    {subDispo.map((subdisp) => {
                      return (
                        <div className="dispo_body_container_second">

                          <div
                            className="dispo_body_container_second_sub"
                            style={
                              subdisp == selectedSubDispo
                                ? { backgroundColor: 'rgb(238 246 252)' }
                                : { backgroundColor: '#fff' }
                            }

                          >
                            <div className="dispo_body_lft">
                              <BsFillHexagonFill />
                            </div>
                            <div className="dispo_body_rht">
                              {subdisp.toUpperCase().replace('_', ' ')}
                            </div>
                            <div
                              className="material-icons dispo_save"

                              style={{ "padding-right": "10px" }}
                              onClick={() => {
                                subdisposelect(subdisp)
                              }}
                            >

                              check
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
      <Dialogbox
        fields={closepopup}
        value={popupdata}
        defaultclass={'dialog-body-default'}
      />
      <Dialogbox
        fields={meetingclosepopup}
        value={meetingdata}
        isshow={isshow}
        className="dialog-body-default1"
        shedulealart={shedulealart}
      />
      <Dialogbox
        fields={savecustform}
        value={cfsavepopup}
        defaultclass={'dialog-body-default'}
      />
    </>
  )
}


const mapStateToProps = (state) => {
  return {
    duration: state.background.socket_message.duration,
    ringing_time: state.background.socket_message.ringing_time
  }
}
export default connect(mapStateToProps, null)(CallDispo)
