let masking='X'
const Format = (string,maskbool, data_return) => {
     let setcampaign = JSON.parse(localStorage.getItem("selectedCampaign_data"))
     if( setcampaign !== null&& setcampaign.masking !== "true"){
            maskbool = undefined
     }
    if(string !== undefined && string !== null && string !== "" && maskbool == undefined){
      return  dataformating(string)
    }else {
        if(maskbool !== undefined && maskbool.maskbool && string !== undefined && string !== null && string !== ""){
            if(isNaN(Number(string)) == false){
               return MaskCharacter(string.toString(),undefined,setcampaign.masking_visible_char)
            }else{
                return dataformating(string)
            }
        }else if(maskbool !== undefined  && !maskbool.maskbool && string !==""){
            return  dataformating(string)
        }else{
            if(data_return){
                return string
            }
            return "N/A"
        }
    }



    
}

function MaskCharacter(str, mask=masking, n = 2) {

    return ('' + str).slice(0, -n)
        .replace(/./g, mask)
        + ('' + str).slice(-n);

}

function dataformating(string) {
    if(isNaN(Number(string))  == false){
      return  string
    }else{
        const arr = string.split(" ");
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    
        }
        const str2 = arr.join(" ")
    
        const arr2 = str2.split("_");
        for (var j = 0; j < arr2.length; j++) {
            arr2[j] = arr2[j].charAt(0).toUpperCase() + arr2[j].slice(1);
    
        }
    
        const main_str = arr2.join(" ")
        return main_str
    }

}

export default Format